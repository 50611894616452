import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Dialog } from '@headlessui/react'
import * as Yup from 'yup'
import { twMerge } from 'tailwind-merge'

import { useChangePassword, useGetUser } from 'api'
import { Modal } from 'components'
import { PasswordField } from 'components/forms'
import { Button } from 'components/ui'
import { useNotification } from 'hooks'

import type { IModal } from 'types'

import styles from 'components/Modal.module.scss'

const ChangePasswordModal: React.FC<{
    modal: IModal
}> = ({ modal }) => {
    const user = useGetUser()
    const showNotification = useNotification()
    const { mutate: changePassword, isLoading: isChangingPassword } =
        useChangePassword()

    const userPasswordRules = useMemo(() => {
        if (user.data) {
            const passwordPolicy = user.data.data.password_policy

            const getPasswordRuleValue = (name: string) => {
                const value = passwordPolicy?.security_settings.find(
                    (item) => item.name === name
                )?.value

                return !isNaN(Number(value)) ? Number(value) : undefined
            }

            const uppercaseLetters =
                getPasswordRuleValue('min_uppercase_letters') || 0
            const numbers = getPasswordRuleValue('min_numbers') || 0
            const specialCharacters =
                getPasswordRuleValue('min_special_characters') || 0

            return {
                length: getPasswordRuleValue('min_password_length') || 0,
                extra: [
                    ...(uppercaseLetters
                        ? [
                              `${uppercaseLetters} ${
                                  uppercaseLetters === 1
                                      ? 'dużą literę'
                                      : uppercaseLetters < 5
                                      ? 'duże litery'
                                      : 'dużych liter'
                              }`,
                          ]
                        : []),
                    ...(numbers
                        ? [
                              `${numbers} ${
                                  numbers === 1
                                      ? 'cyfrę'
                                      : numbers < 5
                                      ? 'cyfry'
                                      : 'cyfr'
                              }`,
                          ]
                        : []),
                    ...(specialCharacters
                        ? [
                              `${specialCharacters} ${
                                  specialCharacters === 1
                                      ? 'znak specjalny'
                                      : specialCharacters < 5
                                      ? 'znaki specjalne'
                                      : 'znaków specjalnych'
                              } (np. !@#$%^&)`,
                          ]
                        : []),
                ],
            }
        }
        return null
    }, [user.data])

    return (
        <Modal modal={modal}>
            <div className={twMerge(styles.inner, 'p-6')}>
                <Dialog.Title as="h3" className={styles.title}>
                    Zarządzanie hasłem
                </Dialog.Title>

                {userPasswordRules && (
                    <div className="text-gray-500 text-sm leading-5 font-normal py-4 mb-4 border-b border-gray-200">
                        Ustaw nowe hasło. Hasło musi zawierać{' '}
                        <span className="font-bold">
                            min. {userPasswordRules.length}{' '}
                            {userPasswordRules.length === 1
                                ? 'znak'
                                : userPasswordRules.length < 5
                                ? 'znaki'
                                : 'znaków'}
                        </span>{' '}
                        i przynajmniej: <br />
                        {userPasswordRules.extra.join(', ')}
                    </div>
                )}

                <Formik
                    onSubmit={(values, formikHelpers) => {
                        changePassword(values, {
                            onSuccess: () => {
                                showNotification({
                                    content: 'Hasło zostało zmienione',
                                    type: 'success',
                                })
                                modal.closeModal()
                            },
                            onError: (error) => {
                                formikHelpers.setErrors(error.errors)
                            },
                        })
                    }}
                    initialValues={{
                        current_password: '',
                        password: '',
                        password_confirmation: '',
                    }}
                    validateOnChange={false}
                    validationSchema={Yup.object({
                        current_password: Yup.string().required(
                            'Aktualne hasło jest wymagane'
                        ),
                        password: Yup.string().required(
                            'Nowe hasło jest wymagane'
                        ),
                        password_confirmation: Yup.string()
                            .oneOf(
                                [Yup.ref('password'), null],
                                'Podane hasła są różne'
                            )
                            .required('Powtórzenie hasła jest wymagane'),
                    })}
                >
                    {() => {
                        return (
                            <Form>
                                <div className="text-sm leading-5 font-medium text-gray-500 mb-4">
                                    Zmień hasło
                                </div>

                                <div className="pb-4 mb-4 border-b border-gray-200">
                                    <PasswordField
                                        name="current_password"
                                        labelText="Aktualne hasło"
                                        type="password"
                                    />
                                </div>

                                <div className="mb-4">
                                    <PasswordField
                                        name="password"
                                        labelText="Nowe hasło"
                                        type="password"
                                    />
                                </div>

                                <div className="mb-6">
                                    <PasswordField
                                        name="password_confirmation"
                                        labelText="Powtórz nowe hasło"
                                    />
                                </div>

                                <div
                                    className={twMerge(
                                        styles.actionContainer,
                                        'flex items-center justify-center gap-3'
                                    )}
                                >
                                    <Button
                                        tabIndex={-1}
                                        variant="default"
                                        type="button"
                                        className="w-full"
                                        onClick={() => modal.closeModal()}
                                    >
                                        Anuluj
                                    </Button>

                                    <Button
                                        tabIndex={-1}
                                        variant="primary"
                                        type="submit"
                                        className="w-full"
                                        loading={isChangingPassword}
                                    >
                                        Zapisz
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Modal>
    )
}

export default ChangePasswordModal
