import React from 'react'
import { useLocation } from 'react-router-dom'

import Avatar from 'components/Avatar'
import { InputText, Button } from 'components/ui'
import ChangePasswordModal from './ChangePasswordModal'

import { phoneWithoutPolandPrefix } from 'helpers'
import { useModal } from 'hooks'

import type { UserResponse } from 'api/types'

const SettingsUser: React.FC<{
    user: UserResponse
    adminView: boolean
}> = ({ user, adminView }) => {
    const location = useLocation()
    const changePasswordModal = useModal(
        !!location.state?.openChangePasswordModal
    )

    return (
        <div className="rounded-md bg-white">
            <div className="divide-gray-200 divide-y">
                <div className="mb-16 py-12 px-6">
                    <div className="text-gray-700">
                        <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x">
                            <div className="col-span-1 pr-8">
                                <div className="text-lg leading-6 font-medium text-gray-900">
                                    Profil
                                </div>
                                <div className="divide-gray-200 divide-y">
                                    <section className="pb-8">
                                        <div className="mt-6">
                                            <div className="relative flex items-center space-x-6">
                                                <div className="flex-shrink-0">
                                                    <Avatar
                                                        url={user.avatar_url}
                                                        abbreviation={
                                                            user.first_name.charAt(
                                                                0
                                                            ) +
                                                            user.last_name.charAt(
                                                                0
                                                            )
                                                        }
                                                        size={16}
                                                    />
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-xl leading-8 font-medium text-gray-900">
                                                        {user.first_name}{' '}
                                                        {user.last_name}
                                                    </p>
                                                    <p className="text-sm text-gray-500 truncate">
                                                        {!!user.role &&
                                                            user.role
                                                                .description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <dl className="mt-6 divide-y divide-gray-200">
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    Imię
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 col-span-2">
                                                    {user.first_name}
                                                </dd>
                                            </div>
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    Nazwisko
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {user.last_name}
                                                </dd>
                                            </div>
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    E-mail
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {user.email}
                                                </dd>
                                            </div>
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    Telefon
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {phoneWithoutPolandPrefix(
                                                        user.phone
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    Adres
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {user.address || '-'}
                                                </dd>
                                            </div>
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    Numer prawa wykonywania
                                                    <br />
                                                    zawodu
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {user.license || '-'}
                                                </dd>
                                            </div>
                                        </dl>
                                    </section>
                                </div>
                            </div>
                            <div className="col-span-1 pl-8">
                                <div className="text-lg leading-6 font-medium text-gray-900">
                                    Uprawnienia
                                </div>
                                <section className="mt-4 pb-8">
                                    <dl className="divide-y divide-gray-200">
                                        <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm text-gray-500">
                                                Specjalizacja
                                            </dt>
                                            <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {user.specializations.length ===
                                                    0 && '-'}
                                                {user.specializations.length >
                                                    0 &&
                                                    user.specializations
                                                        .map((i) => i.name)
                                                        .join(',')}
                                            </dd>
                                        </div>
                                        <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm text-gray-500">
                                                Rola
                                            </dt>
                                            <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {!!user.role && user.role.name}
                                                {!user.role && '-'}
                                            </dd>
                                        </div>
                                        {adminView && (
                                            <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                <dt className="text-sm text-gray-500">
                                                    Typ konta
                                                </dt>
                                                <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {user.account_type_name ||
                                                        '-'}
                                                </dd>
                                            </div>
                                        )}
                                    </dl>
                                </section>
                                {adminView && (
                                    <>
                                        <div className="text-lg leading-6 font-medium text-gray-900">
                                            Status użytkownika
                                        </div>
                                        <section className="mt-4 pb-8">
                                            <dl className="divide-y divide-gray-200">
                                                <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                    <dt className="text-sm text-gray-500">
                                                        Status
                                                    </dt>
                                                    <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                        {user.active
                                                            ? 'Aktywny'
                                                            : 'Nieaktywny'}
                                                    </dd>
                                                </div>
                                                <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                    <dt className="text-sm text-gray-500">
                                                        Blokada
                                                    </dt>
                                                    <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                        {user.blocked
                                                            ? 'Tak'
                                                            : 'Nie'}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </section>
                                    </>
                                )}
                                <div className="text-lg leading-6 font-medium text-gray-900">
                                    Pin użytkownika
                                </div>
                                <section className="pb-8">
                                    <div className="mt-6">
                                        <div className="grid grid-cols-6 gap-x-8 gap-y-8">
                                            <div className="col-span-1">
                                                <InputText
                                                    className="pr-0"
                                                    disabled
                                                    value={user.pin}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {!adminView && (
                                    <>
                                        <div className="text-lg leading-6 font-medium text-gray-900">
                                            Hasło użytkownika
                                        </div>
                                        <section className="mt-4 pb-8">
                                            <dl className="divide-y divide-gray-200">
                                                <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                                                    <dt className="text-sm text-gray-500">
                                                        Zarządzanie hasłem
                                                    </dt>
                                                    <dd
                                                        className="sm:mt-0 sm:col-span-2"
                                                        onClick={() =>
                                                            changePasswordModal.openModal()
                                                        }
                                                    >
                                                        <Button variant="tertiary">
                                                            Zmień hasło
                                                        </Button>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </section>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChangePasswordModal modal={changePasswordModal} />
        </div>
    )
}

export default SettingsUser
