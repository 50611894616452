import React, { useMemo } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { PlusIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'
import * as yup from 'yup'

import ListLayout from 'layouts/ListLayout'
import { Button, Label, Toggle } from 'components/ui'
import {
    ActionBar,
    DropdownField,
    DropdownItem,
    TextField,
} from 'components/forms'
import { SECURITY_SETTINGS } from '../constants'
import { useAccountTypePolicy } from '../hooks'

import type { FormSubmitFn } from 'types'
import { AccountTypeFormType } from '../types/AccountTypeForm'
import { AccountTypeLimitation } from '../api/types'

export const SettingsAccountTypeForm: React.FC<{
    initialFormData: AccountTypeFormType
    handleSubmit: FormSubmitFn<AccountTypeFormType>
    isEdit?: boolean
    isDeleting?: boolean
    handleDelete: () => void
    getDropdownItems: (name: AccountTypeLimitation['name']) => DropdownItem[]
    accountTypesLimitations?: AccountTypeLimitation[]
}> = ({
    initialFormData,
    handleSubmit,
    isEdit,
    isDeleting,
    handleDelete,
    getDropdownItems,
    accountTypesLimitations,
}) => {
    const accountTypePolicy = useAccountTypePolicy()

    const maxPasswordExpiresAfterDays = useMemo(
        () =>
            accountTypesLimitations?.find(
                (item) => item.name === 'password_expires_after_days'
            )?.value || 0,
        [accountTypesLimitations]
    )
    const minUniquePasswordsInRow = useMemo(
        () =>
            accountTypesLimitations?.find(
                (item) => item.name === 'unique_passwords_in_row'
            )?.value || 0,
        [accountTypesLimitations]
    )

    return (
        <Formik
            initialValues={initialFormData}
            enableReinitialize
            onSubmit={(values, formikHelpers) => {
                handleSubmit(values, formikHelpers)
            }}
            validateOnChange={false}
            validationSchema={yup.object({
                password_expires_after_days: yup.object({
                    value: yup.number().when('enabled', {
                        is: true,
                        then: (schema) =>
                            schema
                                .min(0, 'Wymagana liczba większa niż 0')
                                .max(
                                    maxPasswordExpiresAfterDays,
                                    `Wymagana liczba równa lub mniejsza niż ${maxPasswordExpiresAfterDays}`
                                )
                                .required('Pole jest wymagane')
                                .typeError(
                                    'Niepoprawna wartość - wymagane tylko cyfry'
                                ),
                    }),
                }),
                unique_passwords_in_row: yup.object({
                    value: yup.number().when('enabled', {
                        is: (enabled: boolean) =>
                            enabled || minUniquePasswordsInRow > 0,
                        then: (schema) =>
                            schema
                                .min(
                                    minUniquePasswordsInRow,
                                    `Wymagana liczba równa lub większa niż ${minUniquePasswordsInRow}`
                                )
                                .integer(
                                    'Niepoprawna wartość - wymagane tylko cyfry'
                                )
                                .required('Pole jest wymagane')
                                .typeError(
                                    'Niepoprawna wartość - wymagane tylko cyfry'
                                ),
                    }),
                }),
            })}
        >
            {({ setFieldValue, values, errors }) => (
                <Form>
                    <ListLayout
                        renderTitle={
                            <>
                                <span className="font-semibold mr-2">
                                    {isEdit ? 'Edytuj:' : 'Dodaj:'}
                                </span>
                                <span>Typ konta</span>
                            </>
                        }
                    >
                        <div className="rounded-md bg-white px-6 py-12">
                            <div className="divide-gray-200 divide-y">
                                <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x pb-8">
                                    <div className="col-span-1 pr-8 flex flex-col gap-8">
                                        <section className="pb-8">
                                            <TextField
                                                name="name"
                                                labelText="Nazwa konta"
                                            />
                                        </section>

                                        <section className="hidden">
                                            <div className="text-lg leading-6 font-medium text-gray-900 mb-6">
                                                Reguły logowania
                                            </div>

                                            <div>
                                                <FieldArray
                                                    name="regulyLogowania"
                                                    render={() => (
                                                        <table className="w-full">
                                                            {[
                                                                {
                                                                    name: 'Szpital TV',
                                                                    ip: '172.16.0.1',
                                                                    mfa: false,
                                                                    login: true,
                                                                },
                                                                {
                                                                    name: 'Szpital Tablet',
                                                                    ip: '172.16.0.1',
                                                                    mfa: false,
                                                                    login: true,
                                                                },
                                                                {
                                                                    name: 'Wszystkie',
                                                                    mfa: true,
                                                                    login: false,
                                                                    disabled:
                                                                        true,
                                                                },
                                                            ].map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="border-b border-gray-200"
                                                                    >
                                                                        <td className="py-5 pl-6 pr-3 w-1 whitespace-nowrap text-sm leading-5 font-normal text-gray-900">
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </td>
                                                                        <td className="py-5 px-3 w-auto whitespace-nowrap text-sm leading-5 font-medium text-gray-500">
                                                                            {item.ip ||
                                                                                '-'}
                                                                        </td>
                                                                        <td className="py-5 px-3 w-1">
                                                                            <span
                                                                                className={twMerge(
                                                                                    'inline-flex items-center gap-3 text-sm leading-5 font-medium text-gray-900',
                                                                                    item.disabled &&
                                                                                        'opacity-30'
                                                                                )}
                                                                            >
                                                                                <Toggle
                                                                                    checked={
                                                                                        item.mfa
                                                                                    }
                                                                                    handleChange={(
                                                                                        checked
                                                                                    ) =>
                                                                                        setFieldValue(
                                                                                            `regulyLogowania.${index}.mfa`,
                                                                                            checked
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        item.disabled
                                                                                    }
                                                                                />
                                                                                MFA
                                                                            </span>
                                                                        </td>
                                                                        <td className="py-5 pl-3 pr-6 w-1">
                                                                            <span
                                                                                className={twMerge(
                                                                                    'inline-flex items-center gap-3 text-sm leading-5 font-medium text-gray-900',
                                                                                    item.disabled &&
                                                                                        'opacity-30'
                                                                                )}
                                                                            >
                                                                                <Toggle
                                                                                    checked={
                                                                                        item.login
                                                                                    }
                                                                                    handleChange={(
                                                                                        checked
                                                                                    ) =>
                                                                                        setFieldValue(
                                                                                            `regulyLogowania.${index}.login`,
                                                                                            checked
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        item.disabled
                                                                                    }
                                                                                />
                                                                                Logowanie
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </table>
                                                    )}
                                                />
                                            </div>
                                        </section>

                                        <section className="flex flex-col gap-6">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Wymuszenia zmian
                                            </div>

                                            <div>
                                                <div className="flex items-end justify-between gap-2 mb-1">
                                                    <Label className="mb-0">
                                                        {
                                                            SECURITY_SETTINGS
                                                                .password_expires_after_days
                                                                .label
                                                        }
                                                    </Label>

                                                    <Toggle
                                                        checked={
                                                            values
                                                                .password_expires_after_days
                                                                .enabled
                                                        }
                                                        handleChange={(
                                                            selected
                                                        ) => {
                                                            setFieldValue(
                                                                'password_expires_after_days.enabled',
                                                                selected
                                                            )
                                                            !selected &&
                                                                setFieldValue(
                                                                    'password_expires_after_days.value',
                                                                    ''
                                                                )
                                                        }}
                                                    />
                                                </div>

                                                <TextField
                                                    name="password_expires_after_days.value"
                                                    placeholder="-"
                                                    disabled={
                                                        !values
                                                            .password_expires_after_days
                                                            .enabled
                                                    }
                                                />
                                                {errors
                                                    .password_expires_after_days
                                                    ?.value && (
                                                    <span className="mt-1 text-sm text-red-600">
                                                        {
                                                            errors
                                                                .password_expires_after_days
                                                                ?.value
                                                        }
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <div className="flex items-end justify-between gap-2 mb-1">
                                                    <Label className="mb-0">
                                                        {
                                                            SECURITY_SETTINGS
                                                                .unique_passwords_in_row
                                                                .label
                                                        }
                                                    </Label>

                                                    <Toggle
                                                        checked={
                                                            values
                                                                .unique_passwords_in_row
                                                                .enabled
                                                        }
                                                        handleChange={(
                                                            selected
                                                        ) => {
                                                            setFieldValue(
                                                                'unique_passwords_in_row.enabled',
                                                                selected
                                                            )
                                                            !selected &&
                                                                setFieldValue(
                                                                    'unique_passwords_in_row.value',
                                                                    ''
                                                                )
                                                        }}
                                                    />
                                                </div>

                                                <TextField
                                                    name="unique_passwords_in_row.value"
                                                    placeholder="-"
                                                    disabled={
                                                        !values
                                                            .unique_passwords_in_row
                                                            .enabled
                                                    }
                                                />
                                                {errors.unique_passwords_in_row
                                                    ?.value && (
                                                    <span className="mt-1 text-sm text-red-600">
                                                        {
                                                            errors
                                                                .unique_passwords_in_row
                                                                ?.value
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </section>
                                    </div>

                                    <div className="col-span-1 pl-8">
                                        <section className="flex flex-col gap-6">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Zabezpieczenia
                                            </div>

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_password_length
                                                        .label
                                                }
                                                name="settings.min_password_length"
                                                placeholder="-"
                                                items={getDropdownItems(
                                                    'min_password_length'
                                                )}
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_uppercase_letters
                                                        .label
                                                }
                                                name="settings.min_uppercase_letters"
                                                placeholder="-"
                                                items={getDropdownItems(
                                                    'min_uppercase_letters'
                                                )}
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_numbers.label
                                                }
                                                name="settings.min_numbers"
                                                placeholder="-"
                                                items={getDropdownItems(
                                                    'min_numbers'
                                                )}
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .min_special_characters
                                                        .label
                                                }
                                                name="settings.min_special_characters"
                                                placeholder="-"
                                                items={getDropdownItems(
                                                    'min_special_characters'
                                                )}
                                            />

                                            <DropdownField
                                                label={
                                                    SECURITY_SETTINGS
                                                        .account_lockout_threshold
                                                        .label
                                                }
                                                name="settings.account_lockout_threshold"
                                                placeholder="-"
                                                items={getDropdownItems(
                                                    'account_lockout_threshold'
                                                )}
                                            />
                                        </section>
                                    </div>
                                </div>

                                <div className="pt-8">
                                    <ActionBar
                                        leftSide={() => (
                                            <>
                                                {accountTypePolicy.canDelete &&
                                                    isEdit && (
                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            loading={isDeleting}
                                                            onClick={() =>
                                                                handleDelete()
                                                            }
                                                        >
                                                            Usuń
                                                        </Button>
                                                    )}
                                            </>
                                        )}
                                        rightSide={({ isSubmitting }) => (
                                            <>
                                                <Button
                                                    as="link"
                                                    to="/settings/security/account-types"
                                                >
                                                    Anuluj
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="ml-3"
                                                    loading={isSubmitting}
                                                    iconRight={<PlusIcon />}
                                                >
                                                    Zapisz
                                                </Button>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </ListLayout>
                </Form>
            )}
        </Formik>
    )
}
