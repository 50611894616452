import { useMutation, UseMutationResult } from 'react-query'

import axios from 'api/axios'
import { ResponseError, ChangePassword } from 'api/types'

export const changePassword = (data: ChangePassword): Promise<void> =>
    axios.put('/change-password', data)

export const useChangePassword = (): UseMutationResult<
    void,
    ResponseError,
    ChangePassword
> => useMutation((data) => changePassword(data))
